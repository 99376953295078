// dependencies
import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { Grid as MuiGrid, makeStyles, useMediaQuery } from '@material-ui/core'
import classNames from 'classnames'
import { pageTheme } from '@templates/constants'
// context
import { useBannerContext } from '@context/bannerContext'
// components
import StrapiImage from '../Image'
import Link from '../Link'
import ImageWithSku from '../ImageWithSku'

const useStyles = makeStyles(() => ({
  baseBanner: ({ styles, image, children }) => ({
    backgroundColor: styles?.backgroundColor,
    margin: styles?.margin,
    paddingTop: styles?.paddingTop,
    paddingBottom: styles?.paddingBottom,
    ...(image?.url && children
      ? {
          backgroundImage: image?.url ? `url(${image.url}&fm=webp)` : null,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }
      : {}),
    height: styles.height,
  }),
}))

// ? https://github.com/reactjs/reactjs.org/issues/2120
const Grid = forwardRef((props, ref) => <MuiGrid {...props} ref={ref} />)
Grid.displayName = 'MuiGrid'

const getBackgroundImage = (banner, isMobile) => {
  if (banner?.BackgroundImageWithSku?.ImageWithSku) {
    return isMobile
      ? banner?.BackgroundImageWithSku?.ImageWithSku?.MobileImage
      : banner?.BackgroundImageWithSku?.ImageWithSku?.Image
  }
  return isMobile && banner?.MobileBackgroundImage ? banner.MobileBackgroundImage : banner?.BackgroundImage
}

const StrapiBaseBanner = ({
  children = null,
  data: { Banner },
  className = '',
  spacing = 0,
  gridRef = () => {},
  bannerHeight,
  ...props
}) => {
  const isMobile = useMediaQuery(pageTheme.breakpoints.down('sm'))
  const { trackingData, isHero } = useBannerContext()
  const image = getBackgroundImage(Banner, isMobile)
  const imageSizing = Banner?.BackgroundImageSizing
  const bannerLink = Banner?.Link?.Link || Banner?.BackgroundImageWithSku?.ImageWithSku?.Link?.Link
  const hasBannerLink = !!Banner?.Link?.Link
  const imageWithSkuData =
    (Banner?.BackgroundImageWithSku && {
      ...Banner?.BackgroundImageWithSku,
      imageSizing,
      hasBannerLink,
    }) ||
    null

  const styles = {
    backgroundColor: Banner?.BackgroundColor?.ColorHex || null,
    margin: Banner?.BannerMargin ? `${Banner?.BannerMargin}px, 0` : 0,
    height: bannerHeight,
  }
  const linkProps = bannerLink
    ? {
        component: Link,
        data: bannerLink,
        trackingData,
        underline: 'none',
      }
    : {}

  const imageData = {
    desktop: Banner?.BackgroundImage,
    mobile: Banner?.MobileBackgroundImage,
    title: Banner?.Title,
    imageSizing,
  }

  const classes = useStyles({ styles, image, children })

  return children ? (
    <Grid
      className={classNames(classes.baseBanner, className)}
      container
      item
      md={Banner?.ColumnSize || 12}
      ref={gridRef}
      spacing={spacing}
      {...linkProps}
      {...props}
    >
      {children}
    </Grid>
  ) : (
    <Grid item xs={12} className={classNames(classes.baseBanner, className)} ref={gridRef} {...props}>
      {imageWithSkuData && <ImageWithSku data={imageWithSkuData} />}
      {!imageWithSkuData && (
        <StrapiImage
          data={imageData}
          link={bannerLink}
          trackingData={trackingData}
          loading={isHero ? 'eager' : 'lazy'}
        />
      )}
    </Grid>
  )
}

StrapiBaseBanner.propTypes = {
  data: PropTypes.object.isRequired,
  spacing: PropTypes.number,
  height: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  gridRef: PropTypes.any,
  bannerHeight: PropTypes.string,
}

export default StrapiBaseBanner
